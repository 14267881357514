import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { data } from 'jquery';

@Injectable({
  providedIn: 'root',
})
export class RequestService {

  superAdminApiUrl: string = environment.superAdminApiUrl;
  adminApiUrl: string = environment.adminApiUrl;

  constructor(private http: HttpClient) {
    // httpHeaders.append('key', 'fd');
  }
/**Admin */
  getAdminDashboardData(data) {
    return this.http.post<any>(this.adminApiUrl + 'AdminDashboardData',data);
  }

  getGigByAssigMentTypeAndComId(data) {
    return this.http.post<any>(this.adminApiUrl + 'getGigByAssigMentType',data);
  }

  getGigsByAssignMentTypeMapData(data) {
    return this.http.post<any>(this.adminApiUrl + 'getGigsByAssignMentTypeMapData',data);
  }

  signupCompany(data) {
    return this.http.post<any>(this.adminApiUrl + 'adminSignup',data);
  }

  changePasswordCompany(data) {
    return this.http.post<any>(this.adminApiUrl + 'compnay/changePassword',data);
  }


  varifyOtpOnSignUp(data) {
    return this.http.post<any>(this.adminApiUrl + 'varifyOtpOnSignUp',data);
  }

  updateCompanyProfile(data) {
    return this.http.post<any>(this.adminApiUrl + 'profile/update',data);
  }
  getJobLength() {
    return this.http.get<any>(this.adminApiUrl + 'job_length');
  }
  getPaymentType() {
    return this.http.get<any>(this.adminApiUrl + 'payment_type');
  }
  getPaymentTerm() {
    return this.http.get<any>(this.adminApiUrl + 'payment_term');
  }

  addBase(data){
    return this.http.post<any>(this.adminApiUrl + 'cluster_base/store', data)
  }

  getBaseData(data){
    return this.http.post<any>(this.adminApiUrl + 'cluster_base', data)
  }

  deleteBase(data){
    return this.http.post<any>(this.adminApiUrl + 'cluster_base/remove', data)
  }

  updateBase(data){
    return this.http.post<any>(this.adminApiUrl + 'cluster_base/update', data )
  }

  getBaseById(data) {
    return this.http.post<any>(this.adminApiUrl + 'cluster_base/clusterBaseById',data);
  }

  ActDeactBase(data){
    return this.http.post<any>(this.adminApiUrl + 'cluster_base/status',data);
  }

  getBase() {
    return this.http.get<any>(this.adminApiUrl + 'base');
  }

  postGig(data) {
    return this.http.post<any>(this.adminApiUrl + 'gig/store',data);
  }

  postMultipleGigs(data) {
    return this.http.post<any>(this.adminApiUrl + 'gig/upload_multiple_gigs',data);
  }

  getGigFilter(data) {
    return this.http.post<any>(this.adminApiUrl + 'gig', data);
  }

  getGigMap(data) {
    return this.http.post<any>(this.adminApiUrl + 'gig/map', data);
  }

  updateGig(data) {
    return this.http.post<any>(this.adminApiUrl + 'gig/update', data);
  }

  deleteGig(data) {
    return this.http.post<any>(this.adminApiUrl + 'gig/remove', data);
  }

  statusGig(data) {
    return this.http.post<any>(this.adminApiUrl + 'gig/status', data);
  }

  getAppliedUser(data) {
    return this.http.post<any>(this.adminApiUrl + 'gig/applied_gig_user', data);
  }

  getUpdateJobStatus(data) {
    return this.http.post<any>(this.adminApiUrl + 'gig/job_status', data);
  }
  gigUpdateJobStatus(data) {
    return this.http.post<any>(this.adminApiUrl + 'gig/update_gig_status', data);
  }

  followUnfollowCluster(data) {
    return this.http.post<any>(this.adminApiUrl + 'company/followUnfollowCluster', data);
  }

  /* Super Admin */

  getCountry() {
    return this.http.get<any>(this.superAdminApiUrl + 'country');
  }

  getState(country_id) {
    return this.http.post<any>(this.superAdminApiUrl + 'state',{country_id});
  }

  getCity(state_id) {
    return this.http.post<any>(this.superAdminApiUrl + 'city',{state_id});
  }

  getCluster() {
    return this.http.get<any>(this.superAdminApiUrl + 'cluster');
  }
  addDeleteRequest(data){
    return this.http.post<any>(this.superAdminApiUrl + 'delete_account/store',data);
  }
  verifyDeleteRequest(data){
    return this.http.post<any>(this.superAdminApiUrl + 'delete_account/varify_otp',data);
  }
  getDeleteRequest(data){
    return this.http.post<any>(this.superAdminApiUrl + 'delete_account/list',data);
  }
  updateUserDeleteStatus(data){
    return this.http.post<any>(this.superAdminApiUrl + 'delete_account/update_status',data);
  }
  getBaseType(){
    return this.http.get<any>(this.superAdminApiUrl + 'base');
  }

  getClusterSubType(cluster_id) {
    return this.http.post<any>(this.superAdminApiUrl + 'sub_cluster',{cluster_id});
  }


  getCourseByCluster(cluster_id) {
    return this.http.post<any>(this.superAdminApiUrl + 'learning_module/byCluster',{cluster_id});
  }

  getAssignmentType() {
    return this.http.get<any>(this.superAdminApiUrl + 'assignment_type');
  }


  getCourseLevel() {
    return this.http.get<any>(this.superAdminApiUrl + 'learning_module_level');
  }

  getAllCompanyData(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'company/all', data);
  }

  getCompanyFilter(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'company', data);
  }

  getCompanyClusterMap(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'company/companyClusterMap', data);
  }

  addCompany(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'company/store',data);
  }

  getCompanyById(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'company/byId',data);
  }

  updateCompany(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'company/update',data);
  }

  updateCompanyDeviceToken(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'company/update/token',data);
  }

  deleteCompany(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'company/remove',data);
  }

  ActDeactCompany(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'company/status',data);
  }

  getUserFilter(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'user', data);
  }

  getUserClusterMap(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'user/userClusterMap', data);
  }

  getUserById(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'user/byId',data);
  }

  addUser(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'user/store',data);
  }

  updateUser(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'user/update',data);
  }

  deleteUser(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'user/remove',data);
  }

  ActDeactUser(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'user/status',data);
  }

  getAllUserData(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'user/all', data);
  }

  getAllFilteredGigs(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'gig/map', data);
  }

  forgetPassword(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'forget_password', data);
  }

  forgetAdminPassword(data) {
    return this.http.post<any>(this.adminApiUrl + 'forget_password', data);
  }

  forgetUserPassword(data) {
    return this.http.post<any>(this.adminApiUrl + 'company/user/forget_password', data);
  }

  getGigDetail(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'gig/byId', data);
  }

  getAllFilteredGigsWithPagination(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'gig', data);
  }

  getCourseDuration() {
    return this.http.get<any>(this.superAdminApiUrl + 'learning_module_duration');
  }

  getCourseFilter(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'learning_module', data);
  }

  removeLearningModule(data){
    return this.http.post<any>(this.superAdminApiUrl + 'learning_module/remove',data);
  }

  statusLearningModule(data){
    return this.http.post<any>(this.superAdminApiUrl + 'learning_module/status',data);
  }

  learningModuleDetailById(data){
    return this.http.post<any>(this.superAdminApiUrl + 'learning_module/byId',data);
  }

  updateLearningModule(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'learning_module/update',data);
  }

  storeCourse(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'learning_module/store',data);
  }



  getClusterBySlug(slug) {
    return this.http.post<any>(this.superAdminApiUrl + 'cluster/bySlug',{slug});
  }

  listDashboardMap(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'listDashboardMap',data);
  }

  DashboardData(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'DashboardData',data);
  }

  personnelTypeUserCount(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'personnelTypeUserCount',data);
  }
  clusterTypeCompanyCount(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'clusterTypeCompanyCount',data);
  }
  clusterTypeClusterCount(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'clusterTypeClusterCount',data);
  }
  clusterTypeGeoFancingDeviceCount(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'clusterTypeGeoFancingDeviceCount',data);
  }
  clusterTypePersonnelCount(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'clusterTypePersonnelCount',data);
  }
  clusterTypeGigCount(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'clusterTypeGigCount',data);
  }
  getCompaniesBySectorCount(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'getCompaniesBySectorCount',data);
  }
  getUsersBySectorCount(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'getUsersBySectorCount',data);
  }
  getGigsBySectorCount(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'getGigsBySectorCount',data);
  }
  getGigByAssigMentType(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'getGigByAssigMentType',data);
  }

  getGigsByAssignMentTypeMapDataSuperAdmin(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'getGigsByAssignMentTypeMapData',data);
  }

  getGigsPerssonelMapData(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'getGigsPerssonelMapData',data);
  }

  getAllTopics(course_id) {
    return this.http.post<any>(this.superAdminApiUrl + 'topic/all',{course_id});
  }

  getTopicData(course_id) {
    return this.http.post<any>(this.superAdminApiUrl + 'topic',{course_id});
  }

  storeCourseType(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'topic/store',data);
  }

  deleteTopic(data){
    return this.http.post<any>(this.superAdminApiUrl + 'topic/remove',data);
  }

  topicById(data){
    return this.http.post<any>(this.superAdminApiUrl + 'topic/byId',data);
  }

  updateTopic(data){
    return this.http.post<any>(this.superAdminApiUrl + 'topic/update',data);
  }

  statusTopic(data){
    return this.http.post<any>(this.superAdminApiUrl + 'topic/status',data);
  }

  getQuestion(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'question',data);
  }

  storeQuestions(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'question/store',data);
  }

  deleteQuestion(data){
    return this.http.post<any>(this.superAdminApiUrl + 'question/remove',data);
  }

  questionById(data){
    return this.http.post<any>(this.superAdminApiUrl + 'question/byId',data);
  }

  statusQuestion(data){
    return this.http.post<any>(this.superAdminApiUrl + 'question/status',data);
  }

  updateQuestions(data){
    return this.http.post<any>(this.superAdminApiUrl + 'question/update',data);
  }

  getVideo(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'video/byTopic',data);
  }

  storeVideo(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'video/store',data);
  }

  deleteVideo(data){
    return this.http.post<any>(this.superAdminApiUrl + 'video/remove', data)
  }

  getVideoById(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'video/byId',data);
  }

  updateVideo(data){
    return this.http.post<any>(this.superAdminApiUrl + 'video/update', data )
  }

  statusVideo(data){
    return this.http.post<any>(this.superAdminApiUrl + 'video/status', data )
  }

  getCertificate(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'certificate/byTopic',data);
  }

  storeCertificate(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'certificate/store',data);
  }

  deleteCertificate(data){
    return this.http.post<any>(this.superAdminApiUrl + 'certificate/remove',data);
  }

  certificateById(data){
    return this.http.post<any>(this.superAdminApiUrl + 'certificate/byId',data);
  }

  statusCertificate(data){
    return this.http.post<any>(this.superAdminApiUrl + 'certificate/status', data )
  }

  updateCertificate(data){
    return this.http.post<any>(this.superAdminApiUrl + 'certificate/update',data);
  }

  addWorker(data){
    return this.http.post<any>(this.superAdminApiUrl + 'worker_type/store', data)
  }

  getWorkerData(data){
    return this.http.post<any>(this.superAdminApiUrl + 'worker_type', data)
  }

  deleteWorker(data){
    return this.http.post<any>(this.superAdminApiUrl + 'worker_type/remove', data)
  }

  updateWorker(data){
    return this.http.post<any>(this.superAdminApiUrl + 'worker_type/update', data )
  }

  getWorkerById(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'worker_type/byId',data);
  }

  ActDeactWorkerType(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'worker_type/status',data);
  }

  getWorkerTypes(){
    return this.http.get<any>(this.superAdminApiUrl+ 'get_all_work_type')
  }


  addSector(data){
    return this.http.post<any>(this.superAdminApiUrl + 'sector/store', data)
  }

  getSectorData(){
    return this.http.post<any>(this.superAdminApiUrl + 'sector/list', {})
  }

  getAllSector(){
    return this.http.get<any>(this.superAdminApiUrl + 'sector', {})
  }

  deleteSector(data){
    return this.http.post<any>(this.superAdminApiUrl + 'sector/remove', data)
  }

  updateSector(data){
    return this.http.post<any>(this.superAdminApiUrl + 'sector/update', data )
  }

  getSectorById(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'sector/sectorById',data);
  }

  ActDeactSector(data){
    return this.http.post<any>(this.superAdminApiUrl + 'sector/status',data);
  }





  getGigById(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'gig/byId', data);
  }



  getAllCompanyMapping(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'company/mapping', data);
  }

  getCompanyMappingMap(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'company/mapping/map', data);
  }

  getAllPersonnelMapping(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'user/mapping', data);
  }

  getPersonnelMappingMap(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'user/mapping/map', data);
  }

  addSubscription(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'subscription/store',data);
  }

  getSubscription(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'subscription', data);
  }

  updateSubscription(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'subscription/update',data);
  }

  getSubscriptionById(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'subscription/subscriptionById',data);
  }

  deleteSubscription(data){
    return this.http.post<any>(this.superAdminApiUrl + 'subscription/remove', data)
  }

  statusSubscription(data){
    return this.http.post<any>(this.superAdminApiUrl + 'subscription/status',data);
  }

  getAllSubscription(){
    return this.http.get<any>(this.superAdminApiUrl + 'subscription/get_all_subscription')
  }

  getReward(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'reward', data);
  }

  addReward(data){
    return this.http.post<any>(this.superAdminApiUrl + 'reward/store',data);
  }

  deleteReward(data){
    return this.http.post<any>(this.superAdminApiUrl + 'reward/remove', data)
  }

  getRewardById(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'reward/rewardById',data);
  }

  updateReward(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'reward/update',data);
  }

  getCompanyReward(data){
      return this.http.post<any>(this.superAdminApiUrl + 'company/company_subscription',data);
  }

  getCompanySubscription(data){
      return this.http.post<any>(this.superAdminApiUrl + 'company_subscription',data);
  }

  getPackage() {
    return this.http.post<any>(this.superAdminApiUrl + 'package', {});
  }

  addPackage(data){
    return this.http.post<any>(this.superAdminApiUrl + 'package/store',data);
  }

  deletePackage(data){
    return this.http.post<any>(this.superAdminApiUrl + 'package/remove', data)
  }

  getPackageById(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'package/packageById',data);
  }

  updatePackage(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'package/update',data);
  }

  statusPackage(data){
    return this.http.post<any>(this.superAdminApiUrl + 'package/status',data);
  }

  addRedeem(data){
    return this.http.post<any>(this.superAdminApiUrl + 'reward_redeem_management/upsert',data);
  }

  getRedeemManagement(data){
    return this.http.post<any>(this.superAdminApiUrl + 'reward_redeem_management',data);
  }

  getTicketsByUserId(data){
    return this.http.post<any>(this.superAdminApiUrl + 'support/get_ticket',data);
  }

  getTickets(data){
    return this.http.post<any>(this.superAdminApiUrl + 'support/list',data);
  }
  updateTicketStatus(data){
    return this.http.post<any>(this.superAdminApiUrl + 'support/update_status',data);
  }
  getComments(data){
    return this.http.post<any>(this.superAdminApiUrl + 'support/get_ticket_comment',data);
  }
  addComment(data){
    return this.http.post<any>(this.superAdminApiUrl + 'support/add_ticket_comment',data);
  }

  getEmailManagement(data){
    return this.http.post<any>(this.superAdminApiUrl + 'email_management',data);
  }

  storeEmail(data){
    return this.http.post<any>(this.superAdminApiUrl + 'email_management/store',data);
  }

  getEmailById(data){
    return this.http.post<any>(this.superAdminApiUrl + 'email_management/getById',data);
  }

  updateEmail(data){
    return this.http.post<any>(this.superAdminApiUrl + 'email_management/update',data);
  }

  sendEmail(data){
    return this.http.post<any>(this.superAdminApiUrl + 'email/send_email',data);
  }
  sendNotification(data){
    return this.http.post<any>(this.superAdminApiUrl + 'notification/send_notification',data);
  }
  deleteNotification(data){
    return this.http.post<any>(this.superAdminApiUrl + 'notification/delete',data);
  }

  getEmailList(data){
    return this.http.post<any>(this.superAdminApiUrl + 'email/list',data);
  }

  getNotificationManagement(data){
    return this.http.post<any>(this.superAdminApiUrl + 'notification_management',data);
  }

  updateNotification(data){
    return this.http.post<any>(this.superAdminApiUrl + 'notification_management/update',data);
  }

  getNotificationById(data){
    return this.http.post<any>(this.superAdminApiUrl + 'notification_management/getById',data);
  }

  getMessage(data){
    return this.http.post<any>(this.superAdminApiUrl + 'get_message',data);
  }

  sendMessage(data){
    return this.http.post<any>(this.superAdminApiUrl + 'send_message',data);
  }
  sendMessageTo(data){
    return this.http.post<any>(this.superAdminApiUrl + 'sms/send',data);
  }

  getMessageHistory(data){
    return this.http.post<any>(this.superAdminApiUrl + 'get_message_history',data);
  }

  getTicket(data){
    return this.http.post<any>(this.superAdminApiUrl + 'support/get_ticket',data);
  }

  addTickets(data){
      return this.http.post<any>(this.superAdminApiUrl + 'support/generate_ticket',data);
  }

  getComment(data){
    return this.http.post<any>(this.superAdminApiUrl + 'support/get_ticket_comment',data);
  }

  addComments(data){
    return this.http.post<any>(this.superAdminApiUrl + 'support/add_ticket_comment',data);
  }

  mapCourseTypeToCluster(data) {
    return this.http.post<any>(this.superAdminApiUrl + 'digital_service_clusters/update_map_cluster',data);
  }

  getModuleList(data){
    return this.http.post<any>(this.superAdminApiUrl + 'module_list',data);
  }

  getRoleList(data){
    return this.http.post<any>(this.superAdminApiUrl + 'role',data);
  }

  getRoleById(data){
    return this.http.post<any>(this.superAdminApiUrl + 'role/getById',data);
  }

  storeRole(data){
    return this.http.post<any>(this.superAdminApiUrl + 'role/store',data);
  }

  updateRole(data){
    return this.http.post<any>(this.superAdminApiUrl + 'role/update',data);
  }

  getSuperAdminUserList(data){
    return this.http.post<any>(this.superAdminApiUrl + 'list',data);
  }

  getSuperAdminUserById(data){
    return this.http.post<any>(this.superAdminApiUrl + 'getById',data);
  }

  storeSuperAdminUser(data){
    return this.http.post<any>(this.superAdminApiUrl + 'store',data);
  }

  updateSuperAdminUser(data){
    return this.http.post<any>(this.superAdminApiUrl + 'update',data);
  }

  updateSuperAdminDeviceToken(data){
    return this.http.post<any>(this.superAdminApiUrl + 'update_token',data);
  }

  addCluster(data){
    return this.http.post<any>(this.superAdminApiUrl + 'custom_cluster/store',data);
  }

  getAllCluster(data){
    return this.http.post<any>(this.superAdminApiUrl + 'custom_cluster/list',data);
  }

  getClusterForMap(data){
    return this.http.post<any>(this.superAdminApiUrl + 'custom_cluster/listMap',data);
  }

  getClusterById(data){
    return this.http.post<any>(this.superAdminApiUrl + 'custom_cluster/getByClusterId',data);
  }

  updateCluster(data){
    return this.http.post<any>(this.superAdminApiUrl + 'custom_cluster/update',data);
  }

  ActDeactCluster(data){
    return this.http.post<any>(this.superAdminApiUrl + 'custom_cluster/updateStatus',data);
  }

  getAllCustomCluster(){
    return this.http.post<any>(this.superAdminApiUrl + 'custom_cluster/all',{});
  }

  deleteCluster(data){
    return this.http.post<any>(this.superAdminApiUrl + 'custom_cluster/remove', data)
  }

  gigPersonnelReport(data){
    return this.http.post<any>(this.superAdminApiUrl + 'report/gig_personal_report', data)
  }

  gigEmployerReport(data){
    return this.http.post<any>(this.superAdminApiUrl + 'report/employer_report', data)
  }

  gigReport(data){
    return this.http.post<any>(this.superAdminApiUrl + 'report/gig_report', data)
  }

  gigsGigPersonnelReport(data){
    return this.http.post<any>(this.superAdminApiUrl + 'report/gigs_gig_personal_report', data)
  }

  gigsGigEmployerReport(data){
    return this.http.post<any>(this.superAdminApiUrl + 'report/gigs_employer_report', data)
  }

  packagesSalesReport(data){
    return this.http.post<any>(this.superAdminApiUrl + 'report/packages_sales_report', data)
  }

  subscriptionSalesReport(data){
    return this.http.post<any>(this.superAdminApiUrl + 'report/subscriptions_sales_report', data)
  }

  rewardPointReport(data){
    return this.http.post<any>(this.superAdminApiUrl + 'report/reward_points_report', data)
  }

  rewardRedemptionPointReport(data){
    return this.http.post<any>(this.superAdminApiUrl + 'report/reward_redemption_report', data)
  }

  changeEmail(data){
    return this.http.post<any>(this.superAdminApiUrl + 'update', data)
  }

  changePassword(data){
    return this.http.post<any>(this.superAdminApiUrl + 'change_password', data)
  }

  getInterpolationKeys(){
    return this.http.get<any>(this.superAdminApiUrl + 'get_interpolation_keys', {})
  }

  addSupportSubject(data){
    return this.http.post<any>(this.superAdminApiUrl + 'subject/store', data)
  }

  getSupportSubject(){
    return this.http.post<any>(this.superAdminApiUrl + 'subject/list', {})
  }

  getSupportSubjectById(data){
    return this.http.post<any>(this.superAdminApiUrl + 'subject/subjectById', data)
  }

  updateSupportSubject(data){
    return this.http.post<any>(this.superAdminApiUrl + 'subject/update', data)
  }

  deleteSupportSubject(data){
    return this.http.post<any>(this.superAdminApiUrl + 'subject/remove', data)
  }

  statusSupportSubject(data){
    return this.http.post<any>(this.superAdminApiUrl + 'subject/status', data)
  }

  helpAndSupportSubject(data){
    return this.http.post<any>(this.superAdminApiUrl + 'support/list_admin', data)
  }

  getSubjectById(data){
    return this.http.post<any>(this.superAdminApiUrl + 'subject/listByType', data)
  }

  gigAcceptJob(data){
    return this.http.post<any>(this.adminApiUrl + 'gig/accept_job', data)
  }

  updatePublishStatus(data){
    return this.http.post<any>(this.adminApiUrl + 'gig/update_publish_status', data)
  }

  notificationByRole(data){
    return this.http.post<any>(this.superAdminApiUrl + 'notification/listByRole', data)
  }

  companySubscriptionStore(data){
    return this.http.post<any>(this.superAdminApiUrl + 'company_subscription/store', data)
  }

  getCompanySubscriptionById(data){
    return this.http.post<any>(this.superAdminApiUrl + 'company/company_subscription_by_id', data)
  }

  rewardRedeem(data){
    return this.http.post<any>(this.superAdminApiUrl + 'reward_redeem' , data)
  }

  rewardRedeemStore(data){
    return this.http.post<any>(this.superAdminApiUrl + '/company_reward_redeem/store' , data)
  }

  rewardRedeemList(data){
    return this.http.post<any>(this.superAdminApiUrl + 'company_reward_redeem' , data)
  }

  skillStore(data){
    return this.http.post<any>(this.superAdminApiUrl + 'skill/store', data)
  }

  getAllSkill(){
    return this.http.post<any>(this.superAdminApiUrl + 'skill/list', {})
  }
  getAllSkills(data){
    return this.http.post<any>(this.superAdminApiUrl + 'skill/all', data)
  }

  getSkillById(data){
    return this.http.post<any>(this.superAdminApiUrl + 'skill/skillById', data)
  }

  statusSkill(data){
    return this.http.post<any>(this.superAdminApiUrl + 'skill/update_status', data)
  }

  deleteSkill(data){
    return this.http.post<any>(this.superAdminApiUrl + 'skill/remove', data)
  }

  updateSkill(data){
    return this.http.post<any>(this.superAdminApiUrl + 'skill/update', data)
  }

  updateNotificationViewStatus(data){
    return this.http.post<any>(this.superAdminApiUrl + 'notification/update_notification_view_status', data)
  }

  reviewPost(data){
    return this.http.post<any>(this.adminApiUrl + 'user_review/store', data)
  }

  getCompanyReview(data){
    return this.http.post<any>(this.superAdminApiUrl + 'review/company_review', data)
  }

  getUserReview(data){
    return this.http.post<any>(this.superAdminApiUrl + 'review/user_review', data)
  }

  getCurrentSubscriptionByCompanyId(data){
    return this.http.post<any>(this.superAdminApiUrl + 'company/company_subscription_by_company_id', data)
  }

  getPaymentEnable(data){
    return this.http.post<any>(this.superAdminApiUrl + 'company_subscription/payment_enable', data)
  }

  getPaymentEnabelMethod(data){
    return this.http.get<any>(this.adminApiUrl + 'payment_enable', data)
  }

  getCompanyUserList(data){
    return this.http.post<any>(this.adminApiUrl + 'compnay/users',data);
  }

  getCompanyUserById(data){
    return this.http.post<any>(this.adminApiUrl + 'compnay/getById',data);
  }

  storeCompanyUser(data){
    return this.http.post<any>(this.adminApiUrl + 'compnay/store',data);
  }

  updateCompanyUser(data){
    return this.http.post<any>(this.adminApiUrl + 'compnay/update',data);
  }

















}
