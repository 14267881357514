<div class="container">
  <div class="container2">
    <div class="left">
      <img class="image" src="assets/login/NSF.png" alt="logo" />
      <a href="https://web.gigsforkidsmobileapp.com/super_admin_login"
        ><button class="button">Login Teacher<br />Admin</button></a
      >
    </div>
    <div class="right">
      <img class="image" src="assets/login/GIGPILOT-2.png" alt="logo" />
      <a href="https://www.gigpilotworkgroups.com/super_admin_login"
        ><button class="button">Login Gigpilot<br />Admin</button></a
      >
    </div>
  </div>
</div>
